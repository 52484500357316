div.left-bar{
    height: 90vh;
    background: #85bced;
    width: 27%;
    overflow: auto;
}
a.btn-modal{
    background-color: #b9e4ed;
    font-family: Exo 2;
    font-size: 18;
    color: black;
    font-weight: normal;
    width:19%
}
a.needMargin{
    margin-top: 13px;
}
a.lastButton{
    margin-top: 70%;
}
div.clientRequest{
    height:80vh;
}
div.requestApproval{
    height:80vh;
}
button.btn-button{
    background-color: #b9e4ed;
    font-family: Exo 2;
    font-size: 18;
    color: black;
    font-weight: normal;
}
button.btn-button i{
    color: black;
}
a.btn-button{
    background-color: #b9e4ed;
    font-family: Exo 2;
    font-size: 18;
    color: black;
    font-weight: normal;
}
a.btn-button i{
    color: black;
}
div.list-item-header{
    background-color: #b9e4ed;
    height: 45px;
    color: black;
    margin-top: 10px;
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: normal;
}
div.folio-list{
    display: flex;
    background-color: #b9e4ed;
    width: 37%;
    margin-left: 74%;
    margin-top: -31px;
    padding-top: 10px;
    justify-content: space-between;
}

div.item-header{
    background-color: #b9e4ed;
    height: 50px;
    color: black;
    font-weight: normal;
    width: 100%;
    margin-left: 20px;
    margin-top: 10px;
    padding-top: 1%;
    /* padding-left: 45%; */
}
div.collapsible-header{
    background-color: #b9e4ed;
}
div.collapsible-body{
    height: 70vh;
    overflow-y: scroll;
}
/* @media only screen and (min-width: 993px) */
.container {
    min-width: 931px;
}
.textarea{
    margin: 0px;
    height: 163px;
    width: 876px;
}
button.css-1r4vtzz{
    width: 100%;
}
span.view-relatives-name{
    font-style: italic;
    font-size: medium;
    font-weight: 900;
    margin-right: 40px;
}

  div.box-2{
    height: 33.33%;
    width: 100%; 
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: -46px;
  }
  
  /* .box-2 { background-color: #3C3C3C; } */
  
  .goto-btn {
    line-height: 50px;
    height: 50px;
    text-align: center;
    width: 10%;
    cursor: pointer;
  }
  .btn-two {
    color: rgba(0, 0, 0, 0.966);
    transition: all 0.5s;
    position: relative; 
  }
  .btn-two span {
    z-index: 2; 
    display: block;
    position: absolute;
    width: 100%;
    height: 100%; 
    margin-top: -10px;
    font-size: large;
    font-weight: 500;
  }
  .btn-two::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.5s;
    /* border: 1px solid rgba(255,255,255,0.2); */
    background-color: #7cd1f9;
  }
  .btn-two::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.5s;
    /* border: 1px solid rgba(255,255,255,0.2); */
    background-color: #7cd1f9;
  }
  .btn-two:hover::before {
    transform: rotate(-45deg);
    background-color: rgba(255,255,255,0);
  }
  .btn-two:hover::after {
    transform: rotate(45deg);
    background-color: rgba(255,255,255,0);
  }